import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import SolidHero from "../components/solid-hero/solid-hero"

const AGBPage = () => (
  <Layout>
    <SEO title="Allgemeine Geschäftsbedingungen"/>
    <SolidHero title={"Allgemeine Geschäftsbedingungen"} subtitle={() => ""} primary></SolidHero>
    <div className="_17 my-500 _4m">
      <h2>Ziffer 1</h2><b>Bestandteil der Ausbildung</b><br/>Die Fahrausbildung umfasst theoretischen und praktischen
      Fahrunterricht.<br/><br/><b>Schriftlicher Ausbildungsvertrag</b><br/>Sie erfolgt aufgrund eines schriftlichen
      Ausbildungsvertrages.<br/><br/><b>Rechtliche Grundlagen der Ausbildung</b><br/>Der Unterricht wird aufgrund der
      hierfür geltenden gesetzlichen Bestimmungen und der auf ihnen beruhenden Rechtsverordnungen, namentlich der
      Fahrschülerausbildungsordnung, erteilt. Im Übrigen gelten die nachstehenden Bedingungen, die Bestandteile des
      Ausbildungsvertrages sind.<br/><br/><b>Beendigung der Ausbildung</b><br/>Die Ausbildung endet mit der bestandenen
      Fahrerlaubnisprüfung, in jedem Fall nach Ablauf von sechs Monaten seit Abschluss des Ausbildungsvertrages. Wird das
      Ausbildungsverhältnis nach Beendigung fortgesetzt, so sind für die angebotenen Leistungen der Fahrschule die
      Entgelte der Fahrschule maßgeblich, die durch den nach § 32 FahrIG bestimmten Preisaushang zum Zeitpunkt der
      Fortsetzung des Ausbildungsvertrages ausgewiesen sind. Hierauf hat die Fahrschule bei Fortsetzung
      hinzuweisen.<br/><br/><b>Eignungsmängel des Fahrschülers</b><br/>Stellt sich nach Abschluss des
      Ausbildungsvertrages heraus, dass der Fahrschüler die notwendigen körperlichen oder geistigen Anforderungen für
      den Erwerb der Fahrerlaubnis nicht erfüllt, so ist für die Leistungen der Fahrschule Ziffer 6 anzuwenden.
      <h2>Ziffer 2</h2><b>Entgelte, Preisaushang</b><br/>Die im Ausbildungsvertrag zu vereinbarenden Entgelte haben den
      durch Aushang in der Fahrschule bekanntgegebenen zu entsprechen.<h2>Ziffer 3</h2><b>Grundbetrag und Leistungen</b><br/>a)
      Mit dem Grundbetrag werden abgegolten: Die allgemeinen Aufwendungen der Fahrschule sowie die Erteilung des
      theoretischen Unterrichts und erforderliche Vorprüfungen bis zur ersten theoretischen Prüfung. Für die weitere
      Ausbildung im Falle des Nichtbestehens der theoretischen Prüfung ist die Fahrschule berechtigt, den hierfür im
      Ausbildungsvertrag vereinbarten Teilgrundbetrag zu berechnen, höchstens aber die Hälfte des Grundbetrages der
      jeweiligen Klasse; die Erhebung eines Teilgrundbetrages nach nicht bestandener praktischer Prüfung ist
      unzulässig.<br/><br/><b>Entgelt für Fahrstunden und Leistungen</b><br/>b) Mit dem Entgelt für die Fahrstunde von
      45 Minuten Dauer werden abgegolten: Die Kosten für das Ausbildungsfahrzeug, einschließlich der
      Fahrzeugversicherungen sowie die Erteilung des praktischen Fahrunterrichts.<br/><br/><b>Absage der
      Fahrstunden/Benachrichtigungsfrist</b><br/>Kann der Fahrschüler eine vereinbarte Fahrstunde nicht einhalten, so
      ist die Fahrschule unverzüglich zu verständigen. Werden vereinbarte Fahrstunden nicht mindestens 2 Werktage vor
      dem vereinbarten Termin abgesagt, ist die Fahrschule berechtigt, eine Ausfallentschädigung für vom Fahrschüler
      nicht wahrgenommene Fahrstunden in Höhe von drei Vierteln des Fahrstundenentgeltes zu verlangen. Dem Fahrschüler
      bleibt der Nachweis vorbehalten, ein Schaden sei nicht oder in wesentlich geringerer Höhe entstanden.<br/><br/><b>Entgelt
      für die Vorstellung zur Prüfung und Leistungen</b><br/>c) Mit dem Entgelt für die Vorstellung zur Prüfung
      werden abgegolten: Die theoretische und die praktische Prüfungsvorstellung einschließlich der Prüfungsfahrt. Bei
      Wiederholungsprüfungen wird das Entgelt, wie im Ausbildungsvertrag vereinbart, erhoben.<h2>Ziffer 4</h2>
      <b>Zahlungsbedingungen</b><br/>Soweit nichts anderes vereinbart ist, werden der Grundbetrag bei Abschluss des
      Ausbildungsvertrages, das Entgelt für die Fahrstunde vor Antritt derselben, der Betrag für die Vorstellung zur
      Prüfung zusammen mit eventuell verauslagten Verwaltungs- und Prüfungsgebühren spätestens 3 Werktage vor der
      Prüfung fällig.<br/><br/><b>Leistungsverweigerung bei Nichtausgleich der Forderungen</b><br/>Wird das Entgelt
      nicht zur Fälligkeit bezahlt, so kann die Fahrschule die Fortsetzung der Ausbildung sowie die Anmeldung und
      Vorstellung zur Prüfung bis zum Ausgleich der Forderungen verweigern.<br/><br/><b>Entgeltentrichtung bei
      Fortsetzung der Ausbildung</b><br/>Das Entgelt für eine eventuell erforderliche weitere theoretische Ausbildung
      (Ziffer 3a Abs. 2) ist vor Beginn derselben zu entrichten.<h2>Ziffer 5</h2><b>Kündigung des Vertrages</b><br/>Der
      Ausbildungsvertrag kann vom Fahrschüler jederzeit, von der Fahrschule nur aus wichtigem Grund gekündigt werden:
      Ein wichtiger Grund liegt insbesondere vor, wenn der Fahrschüler a) trotz Aufforderung und ohne triftigen Grund
      nicht innerhalb von 4 Wochen seit Vertragsabschluss mit der Ausbildung beginnt oder er diese um mehr als 3 Monate
      ohne triftigen Grund unterbricht,<br/>b) den theoretischen oder den praktischen Teil der Fahrerlaubnisprüfung
      nach jeweils zweimaliger Wiederholung nicht bestanden hat,<br/>c) wiederholt oder gröblich gegen Weisungen oder
      Anordnungen des Fahrlehrers verstößt.<br/><br/><b>Textform der Kündigung</b><br/>Eine Kündigung des
      Ausbildungsvertrages ist nur wirksam, wenn sie in Textform erfolgt.<h2>Ziffer 6</h2><b>Entgelte bei
      Vertragskündigung</b><br/>Wird der Ausbildungsvertrag gekündigt, so hat die Fahrschule Anspruch auf das Entgelt
      für die erbrachten Fahrstunden und eine etwa erfolgte Vorstellung zur Prüfung. Kündigt die Fahrschule aus
      wichtigem Grund oder der Fahrschüler, ohne durch ein vertragswidriges Verhalten der Fahrschule veranlasst zu sein
      (siehe Ziffer5), steht der Fahrschule folgendes Entgelt zu a) 1/5 des Grundbetrages, wenn die Kündigung nach
      Vertragsschluss mit der Fahrschule, aber vor Beginn der Ausbildung erfolgt;<br/>b) 2/5 des Grundbetrages, wenn die
      Kündigung nach Beginn der theoretischen Ausbildung, aber vor der Absolvierung eines Drittels der für die
      beantragten Klassen vorgeschriebenen theoretischen Mindestunterrichtseinheiten erfolgt;<br/>c) 3/5 des
      Grundbetrages, wenn die Kündigung nach der Absolvierung eines Drittels, aber vor dem Abschluss von zwei Dritteln
      der für die beantragten Klassen vorgeschrieben theoretischen Mindestunterrichtseinheiten erfolgt;<br/>d) 4/5 des
      Grundbetrages, wenn die Kündigung nach der Absolvierung von zwei Dritteln der für die beantragten Klassen
      vorgeschriebenen theoretischen Mindestunterrichtseinheiten erfolgt, aber vor deren Abschluss;<br/>e) der volle
      Grundbetrag, wenn die Kündigung nach dem Abschluss der theoretischen Ausbildung erfolgt.<br/>Dem Fahrschüler
      bleibt der Nachweis vorbehalten, dass ein Entgelt oder ein Schaden in der jeweiligen Höhe nicht angefallen oder
      nur geringer angefallen ist. Kündigt die Fahrschule ohne wichtigen Grund oder der Fahrschüler, weil er hierzu
      durch ein vertragswidriges Verhalten der Fahrschule veranlasst wurde, steht der Fahrschule der Grundbetrag nicht
      zu. Eine Vorauszahlung ist zurückzuerstatten<h2>Ziffer 7</h2><b>Einhaltung vereinbarter Termine</b><br/>Fahrschule,
      Fahrlehrer und Fahrschüler haben dafür zu sorgen, dass vereinbarte Fahrstunden pünktlich beginnen. Fahrstunden
      beginnen und enden grundsätzlich an der Fahrschule. Wird auf Wunsch des Fahrschülers davon abgewichen, wird die
      aufgewendete Fahrzeit zum Fahrstundensatz berechnet. Hat der Fahrlehrer den verspäteten Beginn einer Fahrstunde zu
      vertreten oder unterbricht er den praktischen Unterricht, so ist die ausgefallene Ausbildungszeit nachzuholen oder
      gutzuschreiben.<br/><br/><b>Wartezeiten bei Verspätung</b><br/>Verspätet sich der Fahrlehrer um mehr als 15
      Minuten, so braucht der Fahrschüler nicht länger zu warten. Hat der Fahrschüler den verspäteten Beginn einer
      vereinbarten praktischen Ausbildung zu vertreten, so geht die ausgefallene Ausbildungszeit zu seinen Lasten.
      Verspätet er sich um mehr als 15 Minuten, braucht der Fahrlehrer nicht länger zu warten. Die vereinbarte
      Ausbildungszeit gilt dann als ausgefallen (Ziffer 3b Absatz 3).<br/><br/><b>Ausfallentschädigung</b><br/>Die
      Ausfallentschädigung für die vom Fahrschüler nicht wahrgenommene Ausbildungszeit beträgt auch in diesem Falle
      drei Viertel des Fahrstundenentgelts. Dem Fahrschüler bleibt der Nachweis vorbehalten, ein Schaden sei nicht oder
      in wesentlich geringerer Höhe entstanden.<h2>Ziffer 8</h2><b>Ausschluss vom Unterricht</b><br/>Der Fahrschüler
      ist vom Unterricht auszuschließen: a) Wenn er unter dem Einfluss von Alkohol oder anderen berauschenden Mitteln
      steht;<br/>b) Wenn anderweitig Zweifel an seiner Fahrtüchtigkeit begründet
      sind.<br/><br/><b>Ausfallentschädigung</b><br/>Der Fahrschüler hat in diesem Fall ebenfalls als
      Ausfallentschädigung drei Viertel des Fahrstundenentgelts zu entrichten. Dem Fahrschüler bleibt der Nachweis
      vorbehalten, ein Schaden sei nicht oder in wesentlich geringerer Höhe entstanden.<h2>Ziffer 9</h2><b>Behandlung
      von Ausbildungsgerät und Fahrzeugen</b><br/>Der Fahrschüler ist zur pfleglichen Behandlung der
      Ausbildungsfahrzeuge, Lehrmodelle und des Anschauungsmaterials verpflichtet.<h2>Ziffer 10</h2><b>Bedienung und
      Inbetriebnahme von Lehrfahrzeugen</b><br/>Ausbildungsfahrzeuge dürfen nur unter Aufsicht des Fahrlehrers bedient
      oder in Betrieb gesetzt werden. Zuwiderhandlungen können Strafverfolgung und Schadenersatzpflicht zur Folge haben.<br/><br/><b>Besondere
      Pflichten des Fahrschülers bei der Kraftradausbildung</b><br/>Geht bei der Kraftradausbildung oder -prüfung die
      Verbindung zwischen Fahrschüler und Fahrlehrer verloren, so muss der Fahrschüler unverzüglich (geeignete
      Stellen) anhalten, den Motor abstellen und auf den Fahrlehrer warten. Erforderlichenfalls hat er die Fahrschule zu
      verständigen. Beim Verlassen des Fahrzeugs hat er dieses ordnungsgemäß abzustellen und gegen unbefugte Benutzung
      zu sichern.<h2>Ziffer 11</h2><b>Abschluss der Ausbildung</b><br/>Die Fahrschule darf die Ausbildung erst
      abschließen, wenn sie überzeugt ist, dass der Fahrschüler die notwendigen Kenntnisse und Fähigkeiten zum Führen
      eines Kraftfahrzeugs besitzt (§16 FahrlG). Deshalb entscheidet der Fahrlehrer nach pflichtgemäßem Ermessen über
      den Abschluss der Ausbildung (§6 FahrschAusbO).<br/><br/><b>Anmeldung zur Prüfung</b><br/>Die Anmeldung zur
      Fahrerlaubnisprüfung bedarf der Zustimmung des Fahrschülers; sie ist für beide Teile verbindlich. Erscheint der
      Fahrschüler nicht zum Prüfungstermin, ist er zur Bezahlung des Entgelts für die Vorstellung zur Prüfung und
      verauslagter Gebühren verpflichtet.<h2>Ziffer 12</h2><b>Gerichtsstand</b><br/>Hat der Fahrschüler keinen
      allgemeinen Gerichtsstand im Inland oder verlegt er nach Vertragsabschluss seinen Wohnsitz oder gewöhnlichen
      Aufenthaltsort aus dem Inland, oder ist der gewöhnliche Aufenthaltsort zum Zeitpunkt der Klageerhebung nicht
      bekannt, so ist der Sitz der Fahrschule der Gerichtsstand. Diese Bekanntmachung enthält keine Entscheidung über
      die Vereinbarkeit der Empfehlung mit dem Bürgerlichen Gesetzbuch in der ab dem 1.Januar 2002 geltenden Fassung.
      Die Befugnis, nach diesem Gesetz sowie aufgrund anderer gesetzlicher Vorschriften die gerichtliche Überprüfung zu
      verlangen, wird durch diese Bekanntmachung nicht eingeschränkt. Die vorstehende Empfehlung ist unverbindlich. Zu
      ihrer Durchsetzung darf kein wirtschaftlicher, gesellschaftlicher oder sonstiger Druck angewendet werden.
      <h6>Stand: 01/2018</h6>
    </div>

  </Layout>
)

export default AGBPage
